<template>
    <b-sidebar
      id="add-sms-user-sidebar"
      :visible="isAddNewSmsSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-new-sms-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ smsLocal.id ? 'Update': 'Send' }} Sms
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

          <div v-if="smsInfo.sms" class="notification small font-weight-bolder pb-1">
          <b-row>
                    <b-col md="4">
                        <p>Sms Left: <span class="text-primary">{{ smsInfo.sms.total - smsInfo.sms.used }} </span></p>
                    </b-col>
                    <b-col md="4">
                        <p>Sms Used: <span class="text-primary">{{ smsInfo.sms.used }}</span></p>
                    </b-col>
                    <b-col md="4">
                        <p>Sender: <span class="text-primary"> {{ smsInfo.smsSender.sender }}</span></p>
                    </b-col>
                </b-row>
        </div>
            
            <!-- <b-row>
            
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="type"
                  rules="required"
                >
                  <b-form-group
                    label="Type"
                    label-for="type"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="smsLocal.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :clearable="false"
                      :reduce="val => val.value"
                      input-id="type"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              
              <b-col md="6">
                <b-form-group
                  label="Variant"
                  label-for="variant"
                >
                  <v-select
                    v-model="smsLocal.sms"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="variantOptions"
                    :clearable="false"
                    :reduce="val => val.value"
                    input-id="variant"
                  />
                </b-form-group>
              </b-col>
              
            </b-row> -->
  
            <!-- getAllMembers -->
            <b-form-group
                  label="To Members"
                  label-for="member"
                  description="Leave Members & Groups Blank to send to all Members"
                >
                  <v-select
                    v-model="smsLocal.contacts"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="getAllMembers.map(m=>({ name: m.fullName, contact: m.contact1 }))"
                    label="name"
                    :close-on-select="false"
                    :clearable="true"
                    input-id="member"
                    multiple
                    :disabled="smsLocal.groups.length > 0 ? true : smsLocal.appointments.length > 0 ? true : false"
                  />
                  <template #option="{ memID, member }">
                    <span> {{ memID }}</span>
                    <span class="ml-50 align-middle"> {{ member }}</span>
                  </template>
  
                  <template #selected-option="{  member }">
                    <span class="ml-50 align-middle"> {{ member }}</span>
                  </template>    
            </b-form-group>

            <!-- Groups -->
            <b-form-group
              label="To Groups "
              label-for="add-groups"
            >
              <v-select
                v-model="smsLocal.groups"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :close-on-select="false"
                :options="groupsOptions.filter(gp=> gp.members.length > 0)"
                label="name"
                input-id="add-groups"
                :disabled="smsLocal.contacts.length > 0 ? true : smsLocal.appointments.length > 0 ? true : false"
              >

                <template #option="{ name, members }">
                  <span class="ml-50 align-middle"> {{ name }} - {{ members.length }} Members</span>
                </template>

                <template #selected-option="{  name }">
                  <span class="ml-50 align-middle"> {{ name }} </span>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group
              label="To Appointments "
              label-for="add-appointments"
            >
              <v-select
                v-model="smsLocal.appointments"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :close-on-select="false"
                :options="appointmentOptions.filter(gp=> gp.members.length > 0)"
                label="name"
                input-id="add-appointments"
                :disabled="smsLocal.contacts.length > 0 ? true :  smsLocal.groups.length > 0 ? true : false"
              >

                <template #option="{ name, members }">
                  <span class="ml-50 align-middle"> {{ name }} - {{ members.length }} Members</span>
                </template>

                <template #selected-option="{  name }">
                  <span class="ml-50 align-middle"> {{ name }} </span>
                </template>
              </v-select>
            </b-form-group>


            <b-form-group
              label="Scheduled"
              label-for="scheduled"
            >
              <flat-pickr
                id="scheduled"
                class="form-control"
                v-model="smsLocal.scheduled"
                placeholder="YYYY-MM-DD "
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>
            
            <validation-provider
                  #default="validationContext"
                  name="Message"
                  rules="required|min:2"
                >
                <b-form-group
                label="Message"
                label-for="message"
                :state="getValidationState(validationContext)"
                >
                    <b-form-textarea
                        id="message"
                        max="800"
                        rows="4"
                        v-model="smsLocal.message"
                        trim
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>

            
            <div class="notification small font-weight-bolder">
                
                <b-row>
                    <b-col md="4">
                        <p>Length: <span class="text-primary">{{ smsLocal.message.length }}/800 </span></p>
                    </b-col>
                    <b-col md="4">
                        <p>Parts: <span class="text-primary">{{ Math.ceil( smsLocal.message.length / 160) }}/5</span></p>
                    </b-col>
                    <b-col md="4">
                        <p>Cost: <span class="text-primary"> {{ 
                        
                        smsLocal.groups.length > 0 ? 
                        
                        smsLocal.groups.reduce((a, b)=> a + b.members.length, 0) * Math.ceil( smsLocal.message.length / 160)
                        
                        : smsLocal.contacts.length > 0 ? smsLocal.contacts.length * Math.ceil( smsLocal.message.length / 160) : smsLocal.contacts.length == 0 && smsLocal.groups.length == 0 ? getAllMembers.length * Math.ceil( smsLocal.message.length / 160) : 0 
                        
                        }} Sms</span></p>
                    </b-col>
                </b-row>
            </div>

            <b-alert show variant="secondary">
                <div class="alert-body">
                    To send personalised messages, use <span style="cursor: pointer" @click="AddPersonalised('@fullname')" class="text-primary">@fullname</span>  for fullname, <span style="cursor: pointer" @click="AddPersonalised('@firstname')" class="text-primary">@firstname</span> for firstname <span style="cursor: pointer" @click="AddPersonalised('@lastname')" class="text-primary">@lastname</span> for lastname.
                </div>
            </b-alert>

            <!--  -->
            <b-form-group label="">
              <b-form-checkbox
                id="template-1"
                v-model="smsLocal.template"
                name="template-1"
              >
                Save as an <span class="font-weight-bold">SMS Template</span>
              </b-form-checkbox>
            </b-form-group>
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton, BRow, BCol, BFormDatepicker, BAlert
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref, toRefs } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import useMembersList from '../../membership/members-list/useMemberList'
  import useSmsListAddNew from './useSmsListAddNew'
  import flatPickr from 'vue-flatpickr-component'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BFormInvalidFeedback,
      BButton,
      BRow,
      BCol,
      vSelect,
      BFormDatepicker,
      BAlert,
      flatPickr,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BFormCheckbox
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewSmsSidebarActive',
      event: 'update:is-add-new-sms-sidebar-active',
    },
    props: {
      isAddNewSmsSidebarActive: {
        type: Boolean,
        required: true,
      },
      typeOptions:{
        type: Array,
        required: true,
      },
      variantOptions:{
        type: Array,
        required: true,
      },
      smsData: {
        type: Object
      },
      smsInfo: {
        type: Object,
        default: {}
      },
      clearSmsData: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
      }
    },
    setup(props, { emit }) {
      
      const clearFormData = ref(null)
        
      const {
        smsLocal,
        resetSmsLocal,
        onSubmit, 
        groupsOptions,
        appointmentOptions
        
      } = useSmsListAddNew(toRefs(props), clearFormData, emit)
  
      const { getAllMembers } = useMembersList()
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
      } = formValidation(resetSmsLocal, props.clearSmsData)

      const AddPersonalised = (val) => {
        smsLocal.value.message = smsLocal.value.message+' '+val
      }
  
      clearFormData.value = clearForm
  
      return {
        
        smsLocal,
        onSubmit,
  
        refFormObserver,
        getValidationState,
        resetForm,
        getAllMembers,
        groupsOptions,
        appointmentOptions,
        AddPersonalised
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>